// imports
@import './variables';

// tags
* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: $FONT_FAMILY_REGULAR_1;
}

// layout desktop
.d-container {
  height: 100vh;
  width: 100%;
  padding: 3rem;
}
.d-content {
  height: 100%;
  width: 100%;
  background: rgba(209, 217, 230, 0.2);
}
.d-content__grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 3rem;
}

// layout tablet
.t-container {
  height: 100vh;
  width: 100%;
  padding: 3rem;
}
.t-content {
  height: 100%;
  width: 100%;
  background: rgba(209, 217, 230, 0.2);
}
.t-content__grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 3rem;
}

// layout mobile
.m-container {
  height: 100vh;
  width: 100%;
  padding: 78px 3rem 3rem;
}
.m-content {
  height: 100%;
  width: 100%;
}
.m-content__grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 3rem;
}

// navbar
.navbar {
  display: flex;
  flex-direction: column;
}
.navItem {
  width: 13rem;
  display: inline-block;
  padding: 0.9rem;
  margin: 0.2rem 0;
  text-decoration: none;
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  border-radius: $BORDER_RADIUS1;
  color: $GREY4;
}
.navItem--active {
  background: $PRIMARY;
  color: $WHITE;
  transition: $TRANSITION1;
  &:hover {
    background: $PRIMARYHIGHLIGHT;
  }
}

// logo
.logo--light {
  max-width: 13rem;
}

// calendar
.t-calendar__container {
  grid-column-start: 1;
  grid-column-end: 2;
}
.d-calendar__container {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.calendar {
  height: 100%;
  padding-right: 12px;
}
.calendar__dropdownContainer {
  display: flex;
}

// card
.m-card__container {
  grid-column-start: 1;
  grid-column-end: 2;
  margin: 2rem 0 0;
}
.t-card__container {
  grid-column-start: 2;
  grid-column-end: 3;
}
.d-card__container {
  grid-column-start: 3;
  grid-column-end: 4;
}
.card__scrollbarCont {
  padding-right: 12px;
}
.card {
  background-color: $PRIMARY;
  border-radius: $BORDER_RADIUS1;
  margin-bottom: 20px;
  overflow: hidden;
}
.card__top {
  height: 140px;
  position: relative;
  padding-right: 8px;
  & > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.rating__pan {
  padding: 4px 20px;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  border-radius: 0 12px 0 0;
  bottom: 0;
  display: flex;
}
.rating__pan__star__img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.bottom__banner {
  padding: 16px 20px 24px;
  background-color: #f2f2f2;
  margin-right: 8px;
}
.card__meetingName {
  margin: 0;
  font-size: 18px;
  margin-bottom: 14px;
}
.meeting {
  font-weight: bold;
  color: #484c53;
}
.card__date__time {
  font-weight: bold;
  color: $PRIMARY;
  margin: 0;
  font-size: 18px;
  margin-bottom: 18px;
}
.card__location {
  display: flex;
}
.location__icon {
  margin-right: 16px;
}
.location__name {
  color: #6d7179;
}
.create__meeting__btn {
  margin-top: 20px;
}

// details
.m-details__container {
  grid-column-start: 2;
  grid-column-end: 3;
}
.t-details__container {
  grid-column-start: 3;
  grid-column-end: 4;
}
.d-details__container {
  grid-column-start: 4;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  margin-left: $UISIZE1;
}
