// imports
@import '../../styles/variables.scss';

.meeting__details{
    color: $GREY3;
    font-weight: $FONT_WEIGHT_BOLD;
    font-size: $FONT_SIZE_2;
}

.meeting__name{
    font-size: 1.2rem;
    font-weight: 800;
    color: $GREY6;
    line-height: 50px;
    border-radius: $BORDER_RADIUS2;
}

.icontmp{
    width: 15px;
}

.participant__text{
    color: $PRIMARY;
    font-weight: $FONT_WEIGHT_BOLD;
    padding-left: .8rem;
    font-size: $FONT_SIZE_2;
}

.restaurant__text{
    padding-left: .8rem;
}

.restaurant__info{
    padding-top: 1.8rem;
    font-size: $FONT_SIZE_2;
    color: $GREY6;
    font-weight: $FONT_WEIGHT_BOLD;
}

.restaurant__data{
    padding-bottom: .6rem;
}

.restaurant__data1{
    padding-bottom: 1.8rem;
}

.meeting__text{
    font-size: $FONT_SIZE_2;
    color: $GREY6;
    font-weight: $FONT_WEIGHT_BOLD;
    padding-bottom: 1.8rem;
    padding-right: $UISIZE2;
}

.participants{
    font-size: $FONT_SIZE_2;
    color: $GREY6;
    font-weight: $FONT_WEIGHT_BOLD;
}

.pariticipants__details{
    padding-top: .6rem;
}

.leave{
    padding-left: 3rem;
    font-size: $FONT_SIZE_2;
    color: $GREY4;
    font-weight: $FONT_WEIGHT_BOLD;
    padding-right: .6rem;
}

.btn_vote{
    padding-top: 1.6rem;
    margin-left: $UISIZE1;
}
