// imports
@import '../../styles/variables.scss';

.checkboxContainer {
  width: 80%;
}
.checkbox input[type='checkbox'] {
  opacity: 0;
}
.checkbox label {
  position: relative;
  left: 10px;
  color: #787878;
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: $LETTER_SPACING_1;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  transition: $TRANSITION1;
  &::before {
    height: 20px;
    width: 20px;
    position: absolute;
    top: -4px;
    left: -30px;
    border-radius: $BORDER_RADIUS1;
    content: '';
    display: inline-block;
    background: $GREY1;
    transition: $TRANSITION1;
  }
  &::after {
    position: absolute;
    left: -25px;
    top: 0px;
    content: none;
    display: inline-block;
    height: 6px;
    width: 9px;
    border-left: 2px solid $WHITE;
    border-bottom: 2px solid $WHITE;
    transform: rotate(-45deg);
    transition: $TRANSITION1;
  }
}
.checkbox input[type='checkbox']:checked {
  & + label::after {
    content: '';
  }
  & + label::before {
    background: $PRIMARY;
  }
}
