// imports
@import '../../styles/variables.scss';

.overlayContainer {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}
.modalContainer {
  width: 65%;
  height: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem;
  background-color: white;
  transform: translate(-50%, -50%);
  border-radius: $BORDER_RADIUS1;
  overflow-y: hidden;
  & h3 {
    margin: 0 0 1.2rem;
  }
}
.modalContentContainer {
  width: 260px;
  min-height: 245px;
  position: relative;
  margin: 0 0 1.2rem;
}
.modalContent {
  width: 260px;
  position: absolute;
}
.modal__closeButton {
  width: 30px;
  position: absolute;
  right: 18px;
  top: 20px;
  cursor: pointer;
  & svg {
    stroke: $GREY5;
    transition: $TRANSITION1;
    &:hover {
      stroke: $BLACK;
    }
  }
}
#meetingTitle {
  margin: 0 0 1.4rem;
}
.buttonGroup {
  display: flex;
  & button {
    margin: 5px;
  }
}
.timelineContainer {
  display: flex;
  align-items: center;
  & h3 {
    margin: 0 1rem 0 0;
    font-size: $FONT_SIZE_3;
  }
}
